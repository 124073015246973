<template>
  <v-app>
    <div class="element">
      <v-card
        max-height="600"
        max-width="600"
        elevation="2"
        class="mx-auto card"
      >
        <v-img src="../assets/zool.png" />
        <v-card-actions class="center">
          <v-spacer />
          <v-btn large @click="login">
            Login

            <v-icon right> mdi-logout </v-icon>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import backgroundUrl from "../assets/speed.jpg";

export default {
  name: "Login",
  data() {
    return {
      backgroundUrl,
    };
  },
  methods: {
    login() {
      this.$store.dispatch("users/userLogin", {});
    },
  },
};
</script>
<style>
body,
html {
  height: 100%;
  width: 100%;
}

.card {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.element {
  height: 100%;
  background-image: url("../assets/speed.jpg");
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
}
.hg {
  /* The image used */

  /* Half height */
  height: 100%;

  /* Center and scale the image nicely */
}
</style>